import {
  faFacebookSquare,
  faLinkedin,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLocationDot,
  faMobileButton,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Link } from "react-router-dom";
import Service from "../Service";
import PhoneInput from "react-phone-input-2";

export default function Contact() {
  const baseUrl = new Service();
  const [phone, setPhone] = useState("");
  const animatedComponents = makeAnimated();
  const [selectedSkills, setSelectedSkills] = useState("");
  const optionsSkills = [
    { value: "Translation", label: "Translation" },
    { value: "Transcription", label: "Transcription" },
    { value: "Subtitling", label: "Subtitling" },
    { value: "Audio Data Collection ", label: "Audio Data Collection " },
    { value: "Image Annotation", label: "Image Annotation" },
    { value: "Text Annotation", label: "Text Annotation" },
    { value: "Proofreading", label: "Proofreading" },
    { value: "Website Development", label: "Website Development" },
    { value: "Text Data Collection", label: "Text Data Collection" },
    { value: "Website Designing", label: "Website Designing" },
  ];

  const submitContact = (event) => {
    event.preventDefault();
    var name = document.getElementById("name").value;
    var country = document.getElementById("country").value;
    var regexAlphabetical = /^[a-zA-Z ]*$/;
    var regexEmail = /\S+@\S+\.\S+/;
    if(!regexEmail.test(document.getElementById("email").value)){
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Invalid Email Address.",
      });
    }
    if (!regexAlphabetical.test(name)) {
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Please enter a valid name.",
      });
    }
    if (!regexAlphabetical.test(country)) {
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Please enter a valid country.",
      });
    }
    
    const form = document.querySelector("#contactForm");
    const formData = Object.fromEntries(new FormData(form));
    axios
      .post(`${baseUrl.baseUrl()}machiodes/contactus`, {
        ...formData,
        consultingFor: JSON.stringify(selectedSkills),
      })
      .then((response) => {
        // setMessage('Submitted.');
        // setTimeout(() => {
        //     setMessage('');
        // }, 3000);
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "We will contact you shortly",
          });
          form.reset();
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Due to some error your form has not been submitted. You can mail us on info@machiodes.com",
        });
        form.reset();
      });
  };
  return (
    <>
      <div className="container-primary">
        <div className="text-center p-5 mb-5">
          <h2 className="text-center container-header">Contact Us</h2>
        </div>
        <div className="custom-shape-divider-bottom-1682433821">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200,0H0V120H281.94C572.9,116.24,602.45,3.86,602.45,3.86h0S632,116.24,923,120h277Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>

        {/* <div className="custom-shape-divider-bottom">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                    <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                </svg>
            </div> */}
      </div>
      <div className="container-fluid p-5">
        <div className="row">
          <div className="col-lg-6 mt-3">
            <div
              className="card border-0 shadow-lg p-4"
              style={{ minHeight: "100%" }}
            >
              <div
                id="map-container-google-2"
                className="z-depth-1-half map-container mt-3"
              >
                <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.8613449400746!2d77.67975237034197!3d12.852230789777888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6d72bb80cee3%3A0xf4999e9701a61633!2sMachiodes%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1682330464891!5m2!1sen!2sin"
                  style={{ border: "0", minHeight: "202px", minWidth: "100%" }}
                />
              </div>
              <p>Working Office:-</p>
              <p>
                <FontAwesomeIcon
                  className="text-primary"
                  icon={faLocationDot}
                />{" "}
                5/14/8 Janani nagar, Siruppadu Road, Tamil Nadu Pincode:- 628103
              </p>

              <p>Corporate Office:-</p>
              <p>
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="text-primary"
                />{" "}
                25,Doddanagamangala,1st cross Sillicon Town, Phase 2, Electronic
                City, Bengaluru, Karnataka Pincode:- 560100
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faMobileButton}
                  className="text-primary"
                />{" "}
                +91 6360728974
              </p>
              <p>
                <FontAwesomeIcon icon={faEnvelope} className="text-primary" />{" "}
                support@machiodes.com
              </p>

              <h5 className="mt-3">Follow us</h5>
              <p>
                <Link to={"https://www.linkedin.com/company/machiodes/"}>
                  <FontAwesomeIcon
                    className="text-primary me-2"
                    style={{ height: "30px" }}
                    icon={faLinkedin}
                  />
                </Link>

                <Link
                  to={"https://www.facebook.com/profile.php?id=100091827652332"}
                >
                  <FontAwesomeIcon
                    className="text-primary me-2"
                    style={{ height: "30px" }}
                    icon={faFacebookSquare}
                  />
                </Link>

                <Link to={"https://www.youtube.com/@machiodes"}>
                  {" "}
                  <FontAwesomeIcon
                    className="text-primary me-2"
                    style={{ height: "30px" }}
                    icon={faYoutubeSquare}
                  />
                </Link>
              </p>
            </div>
          </div>
          <div className="col-lg-6 mt-3">
            <div className="card border-0 shadow-lg p-4">
              <p>
                Please fill up the form and submit. We will contact to you
                shortly.
              </p>
              <form id="contactForm" onSubmit={submitContact} method="post">
                <div className="row">
                  <div className="col-12 from-group">
                    <label className="form-label" htmlFor="name">
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      required
                    />
                  </div>
                  <div className="col-12 form-group">
                    <label className="form-label" htmlFor="email">
                      Email Id<span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      name="emailId"
                      className="form-control"
                      id="email"
                      required
                    />
                  </div>
                  <div className="col-12 form-group">
                    <label className="form-label" htmlFor="mobile">
                      Mobile<span className="text-danger">*</span>
                    </label>
                    <PhoneInput 
                     inputProps={{
                      name: 'mobile',
                      required: true,
                      autoFocus: true
                    }}            
                    country={"in"}
                    enableSearch={true}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                  />
                  </div>
                  <div className="col-12 form-group">
                    <label className="form-label" htmlFor="country">
                      Country<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="country"
                      className="form-control"
                      id="country"
                      required
                    />
                  </div>
                  <div className="form-group col-12">
                    <label htmlFor="" className="form-label">
                      Consulting For <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={optionsSkills}
                      onChange={setSelectedSkills}
                      id="consultingFor"
                      name="consultingFor"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      required
                    />
                  </div>
                  <div className="col-12 form-group">
                    <label htmlFor="description" className="form-label">
                      Description<span className="text-danger">*</span>
                    </label>
                    <textarea
                      name="description"
                      className="form-control"
                      id=""
                      cols="30"
                      rows="5"
                    ></textarea>
                  </div>
                  <div className="col-12 mt-3 form-group">
                    <button className="btn btn-outline-primary" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
