import { Outlet } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import Navbar from './navbar/Navbar';
import Footer from './footer/Footer';
import './App.css'
import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';


function App() {
  useEffect(() => {
    Aos.init();
  }, []);
  useEffect(() =>{
    document.addEventListener('scroll', (e) => {
      if (window.scrollY > 50) {
          document.querySelector('.navbar').classList.add(['sticky-top']);
          document.querySelector('.navbar').classList.add(['shadow-sm']);
      } else {
          document.querySelector('.navbar').classList.remove(['sticky-top']);
          document.querySelector('.navbar').classList.remove(['shadow-sm']);
      }
    });
  }, []);
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}

export default App;
