import React from "react";
import { Link } from "react-router-dom";
import "./About.css";
import founder from "../assets/images/founder.png";
import md from "../assets/images/md.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { faEye, faHandBackFist } from "@fortawesome/free-regular-svg-icons";
import {
  faFacebookSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

export default function About() {
  return (
    <>
      <div className="container-primary">
        <h2 className="text-center container-header p-5 mb-5">About Us</h2>
        <div className="custom-shape-divider-bottom-1682433821">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200,0H0V120H281.94C572.9,116.24,602.45,3.86,602.45,3.86h0S632,116.24,923,120h277Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
      <div className="container-fluid p-5">
        <div className="row">
          <div className="col-lg-4 col-md-6 mt-3">
            <div className="card text-center border-0 shadow-lg p-4 card-decor-about">
              <div className="card-body">
                <FontAwesomeIcon icon={faBullseye} style={{ height: "50px" }} />
                <h6>Mission</h6>
                <p className="p-2 mt-2" style={{ fontSize: "13px" }}>
                  Provide customer satisfaction and optimum solution to client.
                  To meet the client expectations by providing right tools for
                  the job and finish the job before the given deadline. Also,
                  create and maintain long-term relationship with the client by
                  providing the efficient solution by finishing the task within
                  the given deadline.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-3">
            <div className="card text-center border-0 shadow-lg p-4 card-decor-about">
              <div className="card-body">
                <FontAwesomeIcon icon={faEye} style={{ height: "50px" }} />
                <h6>Vision</h6>
                <p className="p-2 mt-2" style={{ fontSize: "13px" }}>
                  To be at par and beyond by elevation of profit, expansion of
                  business and to get the missions accomplished aligning the
                  core values.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-3">
            <div className="card text-center border-0 shadow-lg p-4 card-decor-about">
              <div className="card-body">
                <FontAwesomeIcon
                  icon={faHandBackFist}
                  style={{ height: "50px" }}
                />
                <h6>Values</h6>
                <p className="p-2 mt-2" style={{ fontSize: "13px" }}>
                  Knowing and acknowledging the core of the organization.
                  Transparency and smart work with hard work.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid container-primary p-5">
        <h3 className="text-center mb-4">Machiodes</h3>
        <p>
          Machiodes Technologies Private Limited ,named Machiodes,being founded
          in 2018 has fledged itself with 50+ employees extending its services
          to both domestic and international zones.We are well grounded
          provisioner in terms of IT and Non IT diligent for our Offshore
          /OnshoreClients. With the ongoing trend of artificial intelligence,
          Machiodes has engaged itself into basic services required for machine
          learning like Annotation and data collections. We are enrolled and
          promptly active into reinforcement of web apps and web services . With
          elite spotlight on effective customer support quality & being an asset
          to boost up metier and ubiquity of industries, Machiodes has been
          working solely with various giants and research analysts to fetch the
          most comprehensive way of customer satisfaction maintaining industries
          competencies. We proudly led our path of motivation following few
          criterias strictly as - We engage professionally trained and skillful
          experts to deliver results Our seasoned team nurtures your goals With
          dedicated experts we manage your requisites through an exhaustive
          tracking system. We leverage our power with new trends and
          technologies to save your money. We serve as an extension and support
          you 24/7*365 through a dedicated team.
        </p>
      </div>
      <div className="container p-5">
        <h4 className="text-center">Meet our team</h4>
        <div className="row mt-5">
          <div
            className=" col-lg-6"
            style={{
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeInUp",
            }}
          >
            <div className="team-item">
              <div className="d-flex">
                <div
                  className="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                  style={{ width: "75px" }}
                >
                  <Link
                    to={
                      "https://www.facebook.com/sweetheartsweta.mishra?mibextid=ZbWKwL"
                    }
                  >
                    <FontAwesomeIcon
                      className="text-light me-2"
                      style={{ height: "30px" }}
                      icon={faFacebookSquare}
                    />
                  </Link>
                  <Link to={"https://www.linkedin.com/in/sweta-mishra-18a20284?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"}>
                    <FontAwesomeIcon
                      className="text-light me-2"
                      style={{ height: "30px" }}
                      icon={faLinkedin}
                    />
                  </Link>
                </div>
                <img
                  src={founder}
                  className="img-fluid team-image rounded shadow-sm"
                  alt="/"
                />
              </div>
              <div className="px-4 py-3">
                <h5 className="fw-bold text-light m-0">Sweta Swita Mishra</h5>
                <small className="text-light">Founder</small>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6"
            style={{
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeInUp",
            }}
          >
            <div className="team-item">
              <div className="d-flex">
                <div
                  className="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                  style={{ width: "75px" }}
                >
                  <Link
                    to={
                      "https://www.facebook.com/ajairockz/"
                    }
                  >
                    <FontAwesomeIcon
                      className="text-light me-2"
                      style={{ height: "30px" }}
                      icon={faFacebookSquare}
                    />
                  </Link>
                  <Link to={"https://www.linkedin.com/ajairockz/"}>
                    <FontAwesomeIcon
                      className="text-light me-2"
                      style={{ height: "30px" }}
                      icon={faLinkedin}
                    />
                  </Link>
                </div>
                <img
                  src={md}
                  className="img-fluid rounded team-image shadow-sm"
                  alt="/"
                />
              </div>
              <div className="px-4 py-3">
                <h5 className="fw-bold text-light m-0">Ajay Sathyaseelan</h5>
                <small className="text-light">Managing Director</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-5 shadow-lg">
        <div className="row">
          <div className="col-md-6 text-end">
            <h4>Say 'Hello' to Machiodes and team to have a kick start.</h4>
          </div>
          <div className="col-md-6 text-center">
            <Link to="/contact" className="btn  btn-primary rounded-pill">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
