import React from "react";
import "./Home.css";
import banner from "../assets/images/banner.png";
import about from "../assets/images/about.png";
import whywe from "../assets/images/why-we.png";
import webdevelopment from "../assets/images/webdevelopment.png";
import transcriptionImage from "../assets/images/transcrption.png";
import translationImage from "../assets/images/translation.png";
import subtitlingImage from "../assets/images/subtitle.png";
import datatyping from "../assets/images/datatyping.png";
import ProofReading from "../assets/images/proofreading.png";
import Typewriter from "react-ts-typewriter";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import Swal from "sweetalert2";
import Service from "../Service";

export default function Home() {
  const baseUrl = new Service();
  const addSubscriber = (event) => {
    event.preventDefault();
    const form = document.querySelector("#addSubscriber");
    const formData = Object.fromEntries(new FormData(form));
    axios
      .post(`${baseUrl.baseUrl()}machiodes/addSubscriber`, formData)
      .then((response) => {
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "You have successfully subscribed.",
          });
          form.reset();
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Failed to subscribe.",
        });
        form.reset();
      });
  };
  return (
    <>
      <div className="container-fluid banner  mb-4">
        <div className="row">
          <div className="col-12">
            <div className="row mb-5">
              <div className="col-lg-6 text-justified">
                <h3 className="text-light p-4 mt-5">
                  Transcription , Translation, Subtitling, Proofreading and
                  Audio Collection Services. Provides expert solutions in the
                  field of AI and machine learning with 24/7*365 support.
                </h3>
                <div
                  className="container-fluid  ms-3"
                  style={{ minHeight: "100px", overflow: "hidden" }}
                >
                  <p className="text-light">
                    {/* <span id="typed"></span> */}
                    <Typewriter
                      speed={100}
                      text={[
                        "Services what you deserve.",
                        "Solutions to make things easier.",
                        "We Go beyond communicating to ‘connecting’ with people.",
                        "Providing outstanding customer service to build loyal customers and a long lasting brand.",
                        "Pioneered being customer - focused.",
                        "Always targeted to be adverted by loyal customer as “incredible service provider.",
                        "Our customers are always served “on -click “.",
                        "Opportunity doesnt knock - it rings.",
                        "Smarter business solutions, better customer relations.",
                        "A brand for a company is like reputation to a man and that is earned by satisfying customer needs.",
                      ]}
                      loop={true}
                    />
                  </p>
                </div>
                <div className="container-fluid">
                  {/* <a href='/' className="btn btn-light shadow-lg">Login</a>
                                <a href='/' className="btn primary shadow-sm ms-4">Watch</a>     */}
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  src={banner}
                  id="banner"
                  alt=""
                  className="img-fluid h-75 mt-5 ms-4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-shape-divider-bottom-1682433821 custom-top">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200,0H0V120H281.94C572.9,116.24,602.45,3.86,602.45,3.86h0S632,116.24,923,120h277Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

      <div className="container-fluid p-5">
        <div className="row">
          <div className="col-lg-6 p-5">
            <div className="">
              <h4 className="heading">About Us</h4>
              <hr className="hr" />
            </div>
            <div className="">
              <p>
                We proudly led our path of motivation following few criterias
                strictly as we engage professionally trained and skillful
                experts to deliver results. Our seasoned team nurtures your
                goals. With dedicated experts we manage your requisites through
                an exhaustive tracking system. We leverage our power with new
                trends and technologies to save your money. We serve as an
                extension and support you 24/7*365 through a dedicated team.
              </p>
            </div>
            <div className="mt-3">
              <div className="row">
                <div className="col-6">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="icons me-2"
                  />
                  Professional Staff
                </div>
                <div className="col-6">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="icons me-2"
                  />
                  Award Winning
                </div>
                <div className="col-6">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="icons me-2"
                  />
                  24/7 Support
                </div>
                <div className="col-6">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="icons me-2"
                  />
                  Fair Prices
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-4">
                  <p>
                    To know more about us{" "}
                    <Link to={"/about"} className="text-decoration-none blink">
                      Click Here.
                    </Link>
                  </p>
                </div>
                <div className="col-12">
                  <i
                    className="fa-brands fa-square-facebook icons"
                    style={{ fontSize: "1.60rem" }}
                  ></i>
                  <i
                    className="fa-brands fa-linkedin ms-3 icons"
                    style={{ fontSize: "1.60rem" }}
                  ></i>
                  <i
                    className="fa-brands fa-square-twitter ms-3 icons"
                    style={{ fontSize: "1.60rem" }}
                  ></i>
                  <i
                    className="fa-brands fa-square-instagram ms-3 icons"
                    style={{ fontSize: "1.60rem" }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <img src={about} data-aos="zoom-in" alt="aboutUs" />
          </div>
        </div>
      </div>

      <div className="container-fluid p-5 container-primary">
        <div className="row text-center">
          <h4>Why to Choose Us</h4>
          <hr className="hr-white mx-auto" />
        </div>
        <div className="row">
          <div className="col-lg-4 text-center">
            <h6
              data-aos="zoom-in"
              style={{
                paddingTop: "72px",
                fontWeight: "bold",
                color: "#53C34C",
              }}
            >
              Provide better and cost effective solutions
            </h6>
            <h6
              data-aos="zoom-in"
              style={{
                paddingTop: "45px",
                fontWeight: "bold",
                color: "#17A4B7",
              }}
            >
              Right toots for right job
            </h6>
            <h6
              data-aos="zoom-in"
              style={{
                paddingTop: "40px",
                fontWeight: "bold",
                color: "#965FDA",
              }}
            >
              Organized work structure
            </h6>
            <h6
              data-aos="zoom-in"
              style={{
                paddingTop: "55px",
                fontWeight: "bold",
                color: "#DD5300",
              }}
            >
              Work with experts for better efficiency and output
            </h6>
          </div>
          <div className="col-lg-4 text-center text-lg-left">
            <img
              src={whywe}
              alt=""
              data-aos="zoom-out"
              data-aos-duration="5000"
              className="img-fluid whywe"
            />
          </div>
          <div className="col-lg-4">
            <p
              data-aos="zoom-in"
              style={{ paddingTop: "72px", fontSize: "14px" }}
            >
              We provide efficient and cost effective solutions to the
              requirements of clients which will be beneficiary to clients.
            </p>
            <p
              data-aos="zoom-in"
              style={{ paddingTop: "15px", fontSize: "14px" }}
            >
              We provide right tools for the right job so that the work will be
              hassle-free and frequency of rework will be less.
            </p>
            <p
              data-aos="zoom-in"
              style={{ paddingTop: "7px", fontSize: "14px" }}
            >
              We plan our work with organized manner so that we can conclude our
              task within given deadline. We believe in client satisfaction and
              a long-term healthy relationship with our clients.
            </p>
            <p
              data-aos="zoom-in"
              style={{ paddingTop: "5px", fontSize: "14px" }}
            >
              Our team comprises of experienced and dedicated professionals who
              work with the objective of getting efficient output according to
              the requirement of the client.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid p-5">
        <div className="row">
          <div className="col-12 text-center">
            <h4 className="heading">Our Services</h4>
            <p>Customer Focussed Service Driven</p>
            <hr className="hr mx-auto" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 mt-4">
            <div
              className="card border-0 shadow-lg p-4 card-decor"
              data-aos="flip-left"
              data-aos-duration="3000"
            >
              <div className="card-body">
                <img src={translationImage} className="imgService" alt="/" />
                <h6 className="heading" style={{ fontWeight: "bold" }}>
                  Translation
                </h6>
                <p>
                  Machiodes also deals with video and audio translation in all
                  regional languages (India) and various foreign languages. It
                  includes audio timestamp division, translation, noise
                  separation etc. It is one of the primary services of
                  Machiodes.
                </p>
                {/* <a href="/" className='text-decoration-none'>Read More<i className="fa-solid fa-arrow-right ms-2"></i></a> */}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mt-4">
            <div
              className="card border-0 shadow-lg p-4 card-decor"
              data-aos="flip-left"
              data-aos-duration="3000"
            >
              <div className="card-body">
                <img src={transcriptionImage} className="imgService" alt="/" />
                <h6 className="heading" style={{ fontWeight: "bold" }}>
                  Transcription
                </h6>
                <p>
                  We also deal with audio segmentation, translation and
                  transcription in all regional languages (India) and various
                  foreign languages.
                </p>
                {/* <a href="/" className='text-decoration-none'>Read More<i className="fa-solid fa-arrow-right ms-2"></i></a> */}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mt-4">
            <div
              className="card border-0 shadow-lg p-4 card-decor"
              data-aos="flip-left"
              data-aos-duration="3000"
            >
              <div className="card-body">
                <img src={datatyping} className="imgService" alt="/" />
                <h6 className="heading" style={{ fontWeight: "bold" }}>
                  Data Collection
                </h6>
                <p>
                  Machiodes deals with data collection such as audio recording
                  in various regional languages, speech collection and image
                  data collection.
                </p>
                {/* <a href="/" className='text-decoration-none'>Read More<i className="fa-solid fa-arrow-right ms-2"></i></a> */}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mt-4">
            <div
              className="card border-0 shadow-lg p-4 card-decor"
              data-aos="flip-left"
              data-aos-duration="3000"
            >
              <div className="card-body">
                <img src={ProofReading} className="imgService" alt="/" />
                <h6 className="heading" style={{ fontWeight: "bold" }}>
                  Proof Reading
                </h6>
                <p>
                  We also do QC/QA for already segmented, transcribed,
                  translated and recorded data. We verify the the processed data
                  is meeting the client’s expectations or not and the data is
                  valid according to client’s requirements.
                </p>
                {/* <a href="/" className='text-decoration-none'>Read More<i className="fa-solid fa-arrow-right ms-2"></i></a> */}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mt-4">
            <div
              className="card border-0 shadow-lg p-4 card-decor"
              data-aos="flip-left"
              data-aos-duration="3000"
            >
              <div className="card-body">
                <img src={subtitlingImage} className="imgService" alt="/" />
                <h6 className="heading" style={{ fontWeight: "bold" }}>
                  Subtitling
                </h6>
                <p>
                  We also do subtitling of video and audio in different
                  languages. The subtitling may be in the same language of
                  audio/video or in different languages. We have expert
                  resources who handle these tasks efficiently
                </p>
                {/* <a href="/" className='text-decoration-none'>Read More<i className="fa-solid fa-arrow-right ms-2"></i></a> */}
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mt-4">
            <div
              className="card border-0 shadow-lg p-4 card-decor"
              data-aos="flip-left"
              data-aos-duration="3000"
            >
              <div className="card-body">
                <img src={webdevelopment} className="imgService" alt="/" />
                <h6 className="heading" style={{ fontWeight: "bold" }}>
                  Web Development
                </h6>
                <p>
                  Machiodes web development unit deals with designing websites.
                  It takes care of overall development of website along with
                  domain registration. It also deals in designing CRMs for
                  client work structure.
                </p>
                {/* <a href="/" className='text-decoration-none'>Read More<i className="fa-solid fa-arrow-right ms-2"></i></a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid p-5 container-primary"></div> */}
      <div className="container-fluid p-5">
        <div className="card border-0 shadow-lg p-4 container-primary ">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6 col-md-6 text-lg-end">
                <h5>Stay connected and updated with Machiodes</h5>
              </div>
              <div className="col-lg-6 col-md-6 text-lg-center text-end blink">
                <Link
                  className="btn btn-primary rounded-pill"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Subscribe
                  <FontAwesomeIcon icon={faBell} className="ms-2" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Please enter details to subscribe with us
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={addSubscriber} id="addSubscriber" method="post">
              <div className="modal-body">
                <label htmlFor="" className="form-label">
                  Are you a ? <span className="text-danger">*</span>
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  required
                  name="type"
                >
                  <option value="translator">Translator</option>
                  <option value="transcriber">Transcriber</option>
                </select>
                <label htmlFor="" className="form-label mt-3">
                  Team Size <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="teamSize"
                  className="form-control"
                  id="teamSize"
                />
                <label htmlFor="" className="form-label mt-3">
                  Email Id <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  name="emailId"
                  className="form-control"
                  id="emailId"
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
