import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import "./Register.css";
import callcenter from "../assets/images/callcenter.jpg";
import jobseekers from "../assets/images/jobseekers.jpg";
import freelancer from "../assets/images/freelancer.jpg";
import projectconsult from "../assets/images/projectConsultant.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Service from "../Service";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

export default function Register() {
  const baseUrl = new Service();
  const [phone, setPhone] = useState("");
  const animatedComponents = makeAnimated();
  const [counter, setCounter] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedSkills, setSelectedSkills] = useState("");
  const addMore = () => {
    setCounter(counter + 1);
  };
  const remove = () => {
    if (counter !== 0) {
      setCounter(counter - 1);
    }
  };
  const optionsLanguage = [
    { value: "Indian-Hindi", label: "Indian-Hindi" },
    { value: "Indian-Marathi", label: "Indian-Marathi" },
    { value: "Indian-Punjabi", label: "Indian-Punjabi" },
    { value: "Indian-Kashmiri", label: "Indian-Kashmiri" },
    { value: "Indian-Gujarati", label: "Indian-Gujarati" },
    { value: "Indian-Manipuri", label: "Indian-Manipuri" },
    { value: "Indian-Assamese", label: "Indian-Assamese" },
    { value: "Indian-Oriya", label: "Indian-Oriya" },
    { value: "Indian-Bengali", label: "Indian-Bengali" },
    { value: "Indian-Telugu", label: "Indian-Telugu" },
    { value: "Indian-Tamil", label: "Indian-Tamil" },
    { value: "Indian-Malyalam", label: "Indian-Malyalam" },
    { value: "Indian-Kannada", label: "Indian-Kannada" },
    { value: "Indian-Urdu", label: "Indian-Urdu" },
    { value: "Foreign-Russian English", label: "Foreign-Russian English" },
    { value: "Foreign-US/UK English", label: "Foreign-US/UK English" },
    { value: "Foreign-Sindhi", label: "Foreign-Sindhi" },
    { value: "Foreign-Pashto", label: "Foreign-Pashto" },
    { value: "Foreign-Farsi", label: "Foreign-Farsi" },
    { value: "Foreign-Dari", label: "Foreign-Dari" },
    { value: "Foreign-Swedish", label: "Foreign-Swedish" },
    { value: "Foreign-Norwegian", label: "Foreign-Norwegian" },
    { value: "Foreign-Amharic", label: "Foreign-Amharic" },
    { value: "Foreign-Tygrinia", label: "Foreign-Tygrinia" },
    { value: "Foreign-Sinhalese", label: "Foreign-Sinhalese" },
    { value: "Foreign-Japanese", label: "Foreign-Japanese" },
    { value: "Foreign-Mandarin Chinese", label: "Foreign-Mandarin Chinese" },
    {
      value: "Foreign-Simplifies Chinese",
      label: "Foreign-Simplifies Chinese",
    },
    { value: "Foreign-Tagalog", label: "Foreign-Tagalog" },
    { value: "Foreign-Finnish", label: "Foreign-Finnish" },
    { value: "Foreign-French", label: "Foreign-French" },
    { value: "Foreign-Egyptian", label: "Foreign-Egyptian" },
    { value: "Foreign-Portugese", label: "Foreign-Portugese" },
    { value: "Foreign-Persian", label: "Foreign-Persian" },
    { value: "Foreign-Arabic", label: "Foreign-Arabic" },
    { value: "Foreign-Oromo", label: "Foreign-Oromo" },
  ];
  const optionsSkills = [
    { value: "Translation", label: "Translation" },
    { value: "Transcription", label: "Transcription" },
    { value: "Subtitling", label: "Subtitling" },
    { value: "Audio Data Collection ", label: "Audio Data Collection " },
    { value: "Image Annotation", label: "Image Annotation" },
    { value: "Text Annotation", label: "Text Annotation" },
    { value: "Proofreading", label: "Proofreading" },
    { value: "Website Development", label: "Website Development" },
    { value: "Text Data Collection", label: "Text Data Collection" },
    { value: "Website Designing", label: "Website Designing" },
  ];

  const registerAsCallCenter = (event) => {
    event.preventDefault();
    var name = document.getElementById("name").value;
    var pincode = document.getElementById("pincode").value;
    var countOfExperienced = document.getElementById("countOfExperienced").value;
    var agentsAvailable = document.getElementById("agentsAvailable").value;
    var countOfFreshers = document.getElementById("countOfFreshers").value;
    var regexPincode = /^\d+$/;
    var regexAgentsAvailable = /^\d+$/;
    var regexCountOfExperienced = /^\d+$/;
    var regexCountOfFreshers = /^\d+$/;
    var regexName = /^[a-zA-Z ]*$/;
    var regexEmail = /\S+@\S+\.\S+/;
    if(!regexEmail.test(document.getElementById("email").value)){
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Invalid Email Address.",
      });
    }
    if (!regexName.test(name)) {
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Invalid Name",
      });
    }
    if (!regexPincode.test(pincode)) {
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Invalid pincode entered. Please check your pincode.",
      });
    }
    if (!regexAgentsAvailable.test(agentsAvailable)) {
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Invalid value entered. Please check number of agents available.",
      });
    }
    if (!regexCountOfExperienced.test(countOfExperienced)) {
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Invalid value in count of experience.",
      });
    }
    if (!regexCountOfFreshers.test(countOfFreshers)) {
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Invalid value in number of freshers.",
      });
    }

    const form = document.querySelector("#registerAsCallCenter");
    const formData = Object.fromEntries(new FormData(form));
    axios
      .post(`${baseUrl.baseUrl()}machiodes/register/callcenter`, {
        ...formData,
        projectInterestedIn: JSON.stringify(selectedSkills),
        language: JSON.stringify(selectedLanguage),
      })
      .then((response) => {
        // setMessage('Submitted.');
        // setTimeout(() => {
        //     setMessage('');
        // }, 3000);
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "You have successfully register with us. Will contact you shortly.",
          });
          form.reset();
          setSelectedSkills(null);
          setSelectedLanguage(null);
          setPhone(null);
        }
        
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Failed to register with us. Please contact to support.",
        });
        form.reset();
        setSelectedSkills(null);
        setSelectedLanguage(null);
        setPhone(null);
      });
  };
  const registerAsProjectConsultant = (event) => {
    event.preventDefault();
    var name2 = document.getElementById("name2").value;
    var pincode2 = document.getElementById("pincode2").value;
    var projectExperienced2 = document.getElementById(
      "projectExperienced2"
    ).value;
    var regexPincode2 = /^\d+$/;
    var regexProjectExperienced2 = /^\d+$/;
    var regexName2 = /^[a-zA-Z ]*$/;
    var regexEmail = /\S+@\S+\.\S+/;
    if(!regexEmail.test(document.getElementById("email2").value)){
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Invalid Email Address.",
      });
    }
    if (!regexName2.test(name2)) {
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Invalid Name",
      });
    }
    if (!regexPincode2.test(pincode2)) {
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Invalid pincode entered. Please check your pincode.",
      });
    }
    if (!regexProjectExperienced2.test(projectExperienced2)) {
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Invalid value in project experience.",
      });
    }
    const form = document.querySelector("#registerAsProjectConsultant");
    const formData = Object.fromEntries(new FormData(form));
    axios
      .post(`${baseUrl.baseUrl()}machiodes/register/projectconsultant`, {
        ...formData,
        projectInterestedIn: JSON.stringify(selectedSkills),
        language: JSON.stringify(selectedLanguage),
      })
      .then((response) => {
        // setMessage('Submitted.');
        // setTimeout(() => {
        //     setMessage('');
        // }, 3000);
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "You have successfully register with us. Will contact you shortly.",
          });
          form.reset();
          setSelectedSkills(null);
          setSelectedLanguage(null);
          setPhone(null);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Failed to register with us. Please contact to support.",
        });
        form.reset();
        setSelectedSkills(null);
          setSelectedLanguage(null);
          setPhone(null);
      });
  };

  const registerAsFreelancer = (event) => {
    event.preventDefault();
    var pincode3 = document.getElementById("pincode3").value;
    var experiencedInTheProject3 = document.getElementById(
      "experiencedInTheProject3"
    ).value;
    var name3 = document.getElementById("name3").value;
    var regexPincode3 = /^\d+$/;
    var regexExperiencedInTheProject3 = /^\d+$/;
    var regexName3 = /^[a-zA-Z ]*$/;
    var regexEmail = /\S+@\S+\.\S+/;
    if(!regexEmail.test(document.getElementById("email3").value)){
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Invalid Email Address.",
      });
    }
    if (!regexName3.test(name3)) {
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Invalid Name",
      });
    }
    if (!regexPincode3.test(pincode3)) {
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Invalid pincode entered. Please check your pincode.",
      });
    }
    if (!regexExperiencedInTheProject3.test(experiencedInTheProject3)) {
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Please enter total number of experienced in the project.",
      });
    }
    const form = document.querySelector("#registerAsFreelancer");
    const formData = Object.fromEntries(new FormData(form));
    axios
      .post(`${baseUrl.baseUrl()}machiodes/register/freelancer`, {
        ...formData,
        projectInterestedIn: JSON.stringify(selectedSkills),
        language: JSON.stringify(selectedLanguage),
      })
      .then((response) => {
        // setMessage('Submitted.');
        // setTimeout(() => {
        //     setMessage('');
        // }, 3000);
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "You have successfully register with us. Will contact you shortly",
          });
          form.reset();
          setSelectedSkills(null);
          setSelectedLanguage(null);
          setPhone(null);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Failed to register with us. Please contact to support.",
        });
        form.reset();
        setSelectedSkills(null);
          setSelectedLanguage(null);
          setPhone(null);
      });
  };

  const registerAsJobseeker = (event) => {
    event.preventDefault();
    var name4 = document.getElementById("name4").value;
    var years = document.getElementById("years").value;
    var regexYears = /^\d+$/;
    var regexName4 = /^[a-zA-Z ]*$/;
    var regexEmail = /\S+@\S+\.\S+/;
    if(!regexEmail.test(document.getElementById("email4").value)){
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Invalid Email Address.",
      });
    }
    if (!regexName4.test(name4)) {
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Invalid Name",
      });
    }
    if (!regexYears.test(years)) {
      return Swal.fire({
        icon: "error",
        title: "Incorrect",
        text: "Please enter total number of years you are experienced with the tool",
      });
    }
    const form = document.querySelector("#registerAsJobseeker");
    const formData = new FormData(form);
    axios
      .post(`${baseUrl.baseUrl()}machiodes/register/jobseeker`, formData, {
        language: JSON.stringify(selectedLanguage),
        expertise: JSON.stringify(selectedSkills),
      })
      .then((response) => {
        // setMessage('Submitted.');
        // setTimeout(() => {
        //     setMessage('');
        // }, 3000);
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "You have successfully register with us. Will contact you shortly",
          });
          form.reset();
          setSelectedSkills(null);
          setSelectedLanguage(null);
          setPhone(null);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Failed to register with us. Please contact to support.",
        });
        form.reset();
        setSelectedSkills(null);
          setSelectedLanguage(null);
          setPhone(null);
      });
  };

  const checkRegisterCard = (event, type) => {
    const allCards = document.querySelectorAll(".registration-type-card");
    allCards.forEach((element, index) => {
      element.classList.remove(["selected"]);
    });
    const allForms = document.querySelectorAll(".registration-type-form");
    allForms.forEach((element, index) => {
      element.classList.remove(["selected"]);
    });

    const card = document.querySelector(
      '.registration-type-card[type="' + type + '"]'
    );
    card.classList.add(["selected"]);
    const form = document.querySelector(
      '.registration-type-form[type="' + type + '"]'
    );
    form.classList.add(["selected"]);
  };

  return (
    <>
      <div className="container-primary">
        <h2 className="text-center container-header p-5 mb-5">
          Register With Us
        </h2>
        <div className="custom-shape-divider-bottom-1682433821">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200,0H0V120H281.94C572.9,116.24,602.45,3.86,602.45,3.86h0S632,116.24,923,120h277Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
        {/* <div className="custom-shape-divider-bottom">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                    <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                </svg>
            </div> */}
      </div>
      <div className="container-fluid p-5">
        <div className="row">
          <h4 className="mb-5">
            Select anyone of the card you want to get register with:-
          </h4>
          <div className="col-lg-3 text-center mt-3">
            <div
              className="card shadow-lg registration-type-card"
              type="1"
              style={{ height: "250px" }}
              onClick={(event) => checkRegisterCard(event, "1")}
            >
              <div className="card-body">
                <div className="registration-type-card-checkbox">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </div>
                <img
                  src={callcenter}
                  alt=""
                  className="border-0"
                  style={{ height: "138px" }}
                />
                <p className="text-center mt-3">Service Provider</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 text-center mt-3">
            <div
              className="card shadow-lg registration-type-card"
              type="2"
              style={{ height: "250px" }}
              onClick={(event) => checkRegisterCard(event, "2")}
            >
              <div className="card-body">
                <div className="registration-type-card-checkbox">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </div>
                <img
                  src={projectconsult}
                  alt=""
                  className="border-0"
                  style={{ height: "138px" }}
                />
                <p className="text-center mt-3">Project Consultant</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 text-center mt-3">
            <div
              className="card shadow-lg registration-type-card"
              type="3"
              style={{ height: "250px" }}
              onClick={(event) => checkRegisterCard(event, "3")}
            >
              <div className="card-body">
                <div className="registration-type-card-checkbox">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </div>
                <img
                  src={freelancer}
                  alt=""
                  className="border-0"
                  style={{ height: "138px" }}
                />
                <p className="text-center mt-3">Freelancer</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 text-center mt-3">
            <div
              className="card shadow-lg registration-type-card"
              type="4"
              style={{ height: "250px" }}
              onClick={(event) => checkRegisterCard(event, "4")}
            >
              <div className="card-body">
                <div className="registration-type-card-checkbox">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </div>
                <img
                  src={jobseekers}
                  alt=""
                  className="border-0"
                  style={{ height: "138px" }}
                />
                <p className="text-center mt-3">Job Seekers</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-5">
        <div className="card registration-type-form" type="1">
          <div className="card-body">
            <h4 className="text-center mb-5">
              Register yourself as Service Provider
            </h4>
            <form
              onSubmit={registerAsCallCenter}
              id="registerAsCallCenter"
              method="post"
            >
              <div className="row">
                <div className="col-lg-3 form-group">
                  <label htmlFor="" className="form-label">
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    name="name"
                    id="name"
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="firmname" className="form-label">
                    Firm Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="firmname"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="mobile" className="form-label">
                    Mobile <span className="text-danger">*</span>
                  </label>
                  {/* <input
                    type="text"
                    className="form-control"
                    required
                    name="mobile"
                    id="mobile"
                  /> */}
                  <PhoneInput 
                     inputProps={{
                      name: 'mobile',
                      required: true,
                      autoFocus: true
                    }}            
                    country={"in"}
                    enableSearch={true}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    placeholder=""
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="email" className="form-label">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="skypeId" className="form-label">
                    Skype Id
                  </label>
                  <input type="text" name="skypeId" className="form-control" />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="LinkedIn Id" className="form-label">
                    LinkedIn Id
                  </label>
                  <input
                    type="text"
                    name="linkdinId"
                    className="form-control"
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="address" className="form-label">
                    Address <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="city" className="form-label">
                    City <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="state" className="form-label">
                    State <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="state"
                    id="state"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="pincode" className="form-label">
                    Pincode <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="pincode"
                    id="pincode"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="agentsAvailable" className="form-label">
                    Agents Available <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="agentsAvailable"
                    id="agentsAvailable"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="countOfFreshers" className="form-label">
                    Count Of Freshers <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="countOfFreshers"
                    id="countOfFreshers"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="countOfExperienced" className="form-label">
                    Count Of Experienced <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="countOfExperienced"
                    id="countOfExperienced"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label htmlFor="" className="form-label">
                    Project Interested In <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={optionsSkills}
                    onChange={setSelectedSkills}
                    value={selectedSkills}
                    id="projectInterestedIn"
                    name="projectInterestedIn"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label
                    htmlFor="otherProjectInterestedIn"
                    className="form-label"
                  >
                    Other Project Interested In{" "}
                    
                  </label>
                  <input
                    type="text"
                    name="otherProjectInterestedIn"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="companyProfile" className="form-label">
                    Company Profile <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="companyProfile"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label htmlFor="" className="form-label">
                    Language <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={optionsLanguage}
                    onChange={setSelectedLanguage}
                    value={selectedLanguage}
                    id="language"
                    name="language"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    required
                  />
                </div>
                <div className="col-lg-12 text-center form-group mt-3">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="card registration-type-form" type="2">
          <div className="card-body">
            <h4 className="text-center mb-5">
              Register yourself as Project Consultant
            </h4>
            <form
              onSubmit={registerAsProjectConsultant}
              id="registerAsProjectConsultant"
              method="post"
            >
              <div className="row">
                <div className="col-lg-3 form-group">
                  <label htmlFor="" className="form-label">
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    name="name"
                    id="name2"
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="firmname" className="form-label">
                    Firm Name <span className="text-danger">*</span>
                  </label>
                  <input type="text" name="firmname" className="form-control" required />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="mobile" className="form-label">
                    Mobile <span className="text-danger">*</span>
                  </label>
                  <PhoneInput 
                     inputProps={{
                      name: 'mobile',
                      required: true,
                      autoFocus: true
                    }}            
                    country={"in"}
                    enableSearch={true}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    placeholder=""
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="email" className="form-label">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email2"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="skypeId" className="form-label">
                    Skype Id
                  </label>
                  <input type="text" name="skypeId" className="form-control" />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="LinkedIn Id" className="form-label">
                    LinkedIn Id
                  </label>
                  <input
                    type="text"
                    name="linkdinId"
                    className="form-control"
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="address" className="form-label">
                    Address <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="city" className="form-label">
                    City <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="state" className="form-label">
                    State <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="state"
                    id="state"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="pincode" className="form-label">
                    Pincode <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="pincode"
                    id="pincode2"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label htmlFor="" className="form-label">
                    Project Interested In <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={optionsSkills}
                    onChange={setSelectedSkills}
                    value={selectedSkills}
                    id="projectInterestedIn2"
                    name="projectInterestedIn"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label
                    htmlFor="otherProjectInterestedIn"
                    className="form-label"
                  >
                    Other Project Interested In
                  </label>
                  <input
                    type="text"
                    name="otherProjectInterestedIn"
                    className="form-control"
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="projectExperienced" className="form-label">
                    Project Experienced <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="projectExperienced"
                    id="projectExperienced2"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label htmlFor="" className="form-label">
                    Language <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={optionsLanguage}
                    value={selectedLanguage}
                    onChange={setSelectedLanguage}
                    id="language"
                    name="language"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    required
                  />
                </div>
                <div className="col-lg-12 text-center form-group mt-3">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="card registration-type-form" type="3">
          <div className="card-body">
            <h4 className="text-center mb-5">
              Register yourself as Freelancer
            </h4>
            <form
              onSubmit={registerAsFreelancer}
              id="registerAsFreelancer"
              method="post"
            >
              <div className="row">
                <div className="col-lg-3 form-group">
                  <label htmlFor="" className="form-label">
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    name="name"
                    id="name3"
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="mobile" className="form-label">
                    Mobile <span className="text-danger">*</span>
                  </label>
                  <PhoneInput 
                     inputProps={{
                      name: 'mobile',
                      required: true,
                      autoFocus: true
                    }}            
                    country={"in"}
                    enableSearch={true}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    placeholder=""
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="email" className="form-label">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email3"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="whatsappNo" className="form-label">
                    Whatsapp No <span className="text-danger">*</span>
                  </label>
                  
                  <PhoneInput 
                     inputProps={{
                      name: 'whatsappNo',
                      required: true,
                      autoFocus: true
                    }}            
                    country={"in"}
                    enableSearch={true}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    placeholder=""
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="skypeId" className="form-label">
                    Skype Id
                  </label>
                  <input type="text" name="skypeId" className="form-control" />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="LinkedIn Id" className="form-label">
                    LinkedIn Id
                  </label>
                  <input
                    type="text"
                    name="linkdinId"
                    className="form-control"
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="address" className="form-label">
                    Address <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="city" className="form-label">
                    City <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="state" className="form-label">
                    State <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="state"
                    id="state"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="pincode" className="form-label">
                    Pincode <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="pincode"
                    id="pincode3"
                    className="form-control"
                    required
                  />
                </div>

                <div className="form-group col-lg-3">
                  <label htmlFor="" className="form-label">
                    Project Interested In <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={optionsSkills}
                    onChange={setSelectedSkills}
                    value={selectedSkills}
                    id="projectInterestedIn"
                    name="projectInterestedIn"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label
                    htmlFor="experiencedInTheProject"
                    className="form-label"
                  >
                    Experienced In The Project{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="experiencedInTheProject"
                    id="experiencedInTheProject3"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label
                    htmlFor="previousProjectHistory"
                    className="form-label"
                  >
                    Previous Project History{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="previousProjectHistory"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label htmlFor="" className="form-label">
                    Language <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={optionsLanguage}
                    value={selectedLanguage}
                    onChange={setSelectedLanguage}
                    id="language"
                    name="language"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    required
                  />
                </div>
                <div className="col-lg-12 text-center form-group mt-3">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="card registration-type-form" type="4">
          <div className="card-body">
            <h4 className="text-center mb-5">
              Register yourself as Job Seekers
            </h4>
            <form
              enctype="multipart/form-data"
              onSubmit={registerAsJobseeker}
              id="registerAsJobseeker"
              method="post"
            >
              <div className="row">
                <div className="col-lg-3 form-group">
                  <label htmlFor="" className="form-label">
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    name="name"
                    id="name4"
                  />
                </div>

                <div className="col-lg-3 form-group">
                  <label htmlFor="mobile" className="form-label">
                    Mobile <span className="text-danger">*</span>
                  </label>
                  <PhoneInput 
                     inputProps={{
                      name: 'mobile',
                      required: true,
                      autoFocus: true
                    }}            
                    country={"in"}
                    enableSearch={true}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    placeholder=""
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="email" className="form-label">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email4"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="whatsappNo" className="form-label">
                    Whatsapp No <span className="text-danger">*</span>
                  </label>
                  <PhoneInput 
                     inputProps={{
                      name: 'whatsappNo',
                      required: true,
                      autoFocus: true
                    }}            
                    country={"in"}
                    enableSearch={true}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    placeholder=""
                    required
                  />
                </div>
                <div className="col-lg-3 form-group">
                  <label htmlFor="skypeId" className="form-label">
                    Skype Id
                  </label>
                  <input type="text" name="skypeId" className="form-control" />
                </div>

                <div className="form-group col-lg-3">
                  <label htmlFor="" className="form-label">
                    Expertise <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={optionsSkills}
                    onChange={setSelectedSkills}
                    value={selectedSkills}
                    id="expertise"
                    name="expertise"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    required
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label htmlFor="" className="form-label">
                    Language <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={optionsLanguage}
                    onChange={setSelectedLanguage}
                    value={selectedLanguage}
                    id="language"
                    name="language"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label htmlFor="tools" className="form-label">
                      Tools <span className="text-danger">*</span>{" "}
                      <Link onClick={addMore} className="text-success">
                        <FontAwesomeIcon icon={faPlus} />
                      </Link>{" "}
                      <Link onClick={remove} className="text-danger">
                        <FontAwesomeIcon icon={faMinus} />
                      </Link>
                    </label>
                    <div className="row">
                      <div className="col-lg-3">
                        <input
                          type="text"
                          placeholder="Tool you have used"
                          name="tool[]"
                          id="tool"
                          className="form-control"
                        />
                      </div>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          placeholder="Years of experience in tool"
                          name="years[]"
                          id="years"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  {Array.from(Array(counter)).map((c, index) => {
                    return (
                      <div className="form-group">
                        <div className="row">
                          <div className="col-lg-3">
                            <input
                              type="text"
                              placeholder="Tool you have used"
                              name="tool[]"
                              id="tool"
                              className="form-control"
                              required
                            />
                          </div>
                          <div className="col-lg-3">
                            <input
                              type="text"
                              placeholder="Years of experience in tool"
                              name="years[]"
                              id="years"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="form-group col-lg-3">
                  <label htmlFor="" className="form-label">
                    Upload CV <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    accept="application/pdf"
                    className="form-control"
                    name="file"
                    placeholder="Only pdf files are accepted"
                    required
                  />
                </div>
                <div className="col-lg-12 text-center form-group mt-3">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
