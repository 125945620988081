import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container-fluid">
          <div className="text-center p-4">
            © {new Date().getFullYear()} Copyright:&nbsp;
            <Link className="text-reset fw-bold" to={"/"}>
              machiodes.com
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
